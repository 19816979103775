import React, { lazy, Suspense } from "react";
import { DataProvider } from "../redux/context/dataProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Loading from "../components/userLayout/loading";
import postErrorToServer from "../services/sendErrorReportToServer";
import ErrorFallback from "../components/errorFallback/errorFallback";
import { LanguageAndServerProvider } from "../redux/context/languageAndServerProvider";
import ReservationProvider from "../redux/context/reservationProvider";
import RateRide from "../components/RateRide/RateRide";
import './App.css';


const InvalidUser = lazy(() => import('../pages/InvalidUser/InvalidUser'));
const MappingApp = lazy(() => import('../components/mappingApp/mapping'));
const Register = lazy(() => import('../pages/RegisterNewUser/register'));
const Home = lazy(() => import('../pages/Home/Home'));
const TaskTracker = lazy(() => import('../pages/TaskTracker/TaskTracker'));
const MissionOwner = lazy(() => import('../pages/missionOwner/MissionOwner'));
const UserLayout = lazy(() => import('../components/userLayout/userLayout'));
const LocationChecker = lazy(() => import('../pages/LocationChecker/LocationChecker'));

function App(){

  const logAppplicationError = (error, info) => {
      const currentInfo = info && info.componentStack ? info.componentStack :info;
      postErrorToServer(error, currentInfo );
  };

  return ( 
  <>
     <ErrorBoundary FallbackComponent={ErrorFallback} onError={logAppplicationError} > 
        <Router>
          <LanguageAndServerProvider>
          <Suspense fallback={<><Loading /></>}>
          <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/rate" component={RateRide} />
                <DataProvider >
                <Route exact path="/register" >
                  <Register />
                </Route>
                <Route exact path="/invalid" >
                  <InvalidUser />
                </Route>
                  <Route exact path="/mapping" >
                    <MappingApp />
                  </Route>
                    <Route exact path="/location" >
                      <LocationChecker />
                    </Route>
                    <Route exact path={['/user/:user_id']} >
                      <ReservationProvider >
                        <UserLayout />
                      </ReservationProvider>
                  </Route>
                  <Route exact path={['/task-tracker/:mission_id/:task_id']}>
                    <TaskTracker />
                  </Route>
                  <Route exact path={['/mission-owner/:mission_id']}>
                    <MissionOwner />
                  </Route>
              </DataProvider>
            </Switch>
          </Suspense>
        </LanguageAndServerProvider>
      </Router>
    </ErrorBoundary>
  </>
  )
}

export default App;
